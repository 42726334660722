import React from 'react';
import './Rating.css';
import PropTypes from 'prop-types'

export default function Rating(props) {
  const stars = [0, 0, 0, 0, 0].map((_, i) =>
    (i < props.value)
      ? <span key={i}>&#9733; </span>
      : <span key={i}>&#9734; </span>
  );
  return (
    <div className="rating">
      {stars}
    </div>
  );
}
Rating.propTypes = {
  value: (props, propName, componentName) => {
    const prop = props[propName];
    if (!prop) {
      return new Error(`${propName} is required in ${componentName}`)
    }
    if (typeof prop !== 'number') {
      return new Error(`Invalid prop, ${propName} must be a number in ${componentName}`)
    }
    if (prop < 1 || prop > 5) {
      return new Error(`Invalid prop, ${propName} must be in the range of 1 -5 in ${componentName}. ${prop} found`)
    }
  }
};
Rating.defaultProps = {
  value: 1
};
